import axios from 'axios';
import { redirectToLogin } from '../utils/navigation';
import { API_BASE_URL } from '../config/api';
import { clearUserInfo } from '../utils/auth';
import i18n from '../config/i18n';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true // Permite el envío de cookies en cada solicitud
});

// Interceptor para agregar el idioma a cada solicitud
api.interceptors.request.use(
  (config) => {
    const language = i18n.global.locale; 
    config.headers['Accept-Language'] = language;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    const { title, detail, data } = response.data;
    return {
      title: title || "Success",
      detail: detail || "",
      data: data !== undefined ? data : response.data,
    };
  },
  (error) => {
    if (error.response) {
      
      if (error.response.status === 401) {
        clearUserInfo();
        redirectToLogin();
      }

      const { title, detail, data } = error.response.data;
      return Promise.reject({
        title: title || "Error",
        detail: detail || "Ha ocurrido un error",
        data: data || null,
        status: error.response.status,
      });
    }
    return Promise.reject(error);
  }
);

export const login = async (email, password, rememberMe) => {
  return await api.post('/user/login', { email, password, rememberMe });
};

export const logout = async () => {
  return await api.post('/user/logout');
};

export const askQuestion = async ({ question, conversationId = null, requiresDatabaseQuery = false, databaseConnectionId = null }) => {
  const payload = {
    question,
    conversationId,
    requiresDatabaseQuery,
    databaseConnectionId
  };

  return await api.post('/rag/ask', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('files', file);
  return await api.post('/file/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const listFiles = async () => {
  return await api.get('/file/list');
};

export const deleteFile = async (fileName) => {
  return await api.delete(`/file/delete/${fileName}`);
};

export const getAccountList = async () => {
  return await api.get('/account/list');
};

export const getAccount = async () => {
  return await api.get('/account/getAccount');
};

export const getAccountUsage = async (accountId, year) => {
  return await api.get(`/account/usage/${accountId}/${year}`);
};

export const createAccount = async (accountData) => {
  return await api.post('/account/create', accountData);
};

export const updateAccount = async (accountId, accountData) => {
  return await api.put(`/account/update/${accountId}`, accountData);
};

export const deleteAccount = async (accountId) => {
  return await api.delete(`/account/delete/${accountId}`);
};

export const getUserList = async (accountId) => {
  return await api.get(`/user/listUsers/${accountId}`);
};

export const getUser = async () => {
  return await api.get('/user');
};

export const createUser = async (userData) => {
  return await api.post('/user/register', userData);
};

export const updateUser = async (userData) => {
  return await api.put('/user/self', userData);
};

export const updateUserById = async (accountId, userId, userData) => {
  return await api.put(`/user/${accountId}/${userId}`, userData);
};

export const deleteUser = async (accountId, userId) => {
  return await api.delete(`/user/${accountId}/${userId}`);
};

export const getUserRoles = async () => {
  return await api.get('/user/getRoles');
};

export const requestPasswordReset = async (email) => {
  return await api.post('/user/request-password-reset', { email });
};

export const resetPassword = async (token, newPassword) => {
  return await api.post("/user/reset-password", {
    token,
    newPassword,
  });
};

export const getPricingPlanPublicList = async () => {
  return await api.get('/pricingplan/publicList');
};

export const getPricingPlanList = async () => {
  return await api.get('/pricingplan/list');
};

export const createPricingPlan = async (planData) => {
  return await api.post('/pricingplan/create', planData);
};

export const updatePricingPlan = async (planId, planData) => {
  return await api.put(`/pricingplan/update/${planId}`, planData);
};

export const deletePricingPlan = async (planId) => {
  return await api.delete(`/pricingplan/delete/${planId}`);
};

export const getAIProviderList = async () => {
  return await api.get('/aiprovider/list');
};

export const createAIProvider = async (providerData) => {
  return await api.post('/aiprovider/create', providerData);
};

export const updateAIProvider = async (providerId, providerData) => {
  return await api.put(`/aiprovider/update/${providerId}`, providerData);
};

export const deleteAIProvider = async (providerId) => {
  return await api.delete(`/aiprovider/delete/${providerId}`);
};

export const assignProviderToPlan = async (planId, providerId) => {
  return await api.post(`/pricingplan/${planId}/assign-provider/${providerId}`);
};

export const createPayment = async (accountId, nextBillingDate) => {
  return await api.post(`/payments/create/${accountId}`, null, {
    params: { nextBillingDate }
  });
};

export const disablePayment = async (accountId) => {
  return await api.put(`/payments/disable/${accountId}`);
};

export const getPaymentStatus = async (accountId) => {
  return await api.get(`/payments/paymentStatus/${accountId}`);
};

export const getConversations = async () => {
  return await api.get('/rag/conversations');
};

export const getConversation = async (id) => {
  return await api.get(`/rag/conversations/${id}`);
};

export const renameConversation = async (id, newName) => {
  return await api.put(`/rag/conversations/${id}/rename`, JSON.stringify(newName), {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const deleteConversation = async (id) => {
  return await api.delete(`/rag/conversations/${id}`);
};

export const createCheckoutSession = async (checkoutData) => {
  return await api.post('/payments/checkout', checkoutData);
};

export const getStripeLink = async (customerId) => {
  return await api.get(`/payments/customer-portal/${customerId}`);
};

export const checkEmailExists = async (email) => {
  return await api.get(`/account/checkEmail/${email}`);
};

export const createDatabaseConnection = async (data) => {
  return await api.post(`database-connection/create`, data);
};

export const listDatabaseConnections = async () => {
  return await api.get(`database-connection/list`);
};

export const updateDatabaseConnection = async (id, data) => {
  return await api.put(`database-connection/update/${id}`, data);
};

export const deleteDatabaseConnection = async (id) => {
  return await api.delete(`database-connection/delete/${id}`);
};

export default api;