import { title } from "process";

export default {
  appName: "Aignis",
  genericError: "Ha ocurrido un error, por favor inténtalo de nuevo más tarde.",
  close: "Cerrar",
  pwa: {
    installTitle: "Instala Aignis",
    installDescription: "Instálate Aignis para acceder más rápido",
    install: "Instalar",
    iosInstructionsTitle: "Instalar Aignis en iOS",
    iosStep1:
      "Pulsa el icono de compartir en la barra inferior de tu navegador.",
    iosStep2:
      "Desplázate hacia abajo y selecciona 'Añadir a pantalla de inicio'.",
    iosStep3: "Pulsa 'Añadir' en la esquina superior derecha.",
    gotIt: "¡Entendido!",
  },
  home: {
    meta: {
      title:
        "Aignis - Inteligencia artificial aplicada a tu base de conocimientos",
      description:
        "Descubre el poder de la inteligencia artificial aplicada a tu base de conocimientos. Aignis te permite obtener respuestas precisas y relevantes en segundos.",
    },
    menu: {
      about: "¿Quién es Aignis?",
      features: "Características",
      pricing: "Precios",
      contact: "Contacto",
      login: "Iniciar sesión",
    },
    hero: {
      title: "Potencia tu conocimiento<br>con ",
      subtitle1:
        "Descubre el poder de la inteligencia artificial aplicada a tu base de conocimientos.",
      subtitle2:
        "Aignis te permite obtener respuestas precisas y relevantes en segundos utilizando la información que más importa: la tuya.",
      cta: "Comenzar ahora",
      learnMore: "Saber más",
    },
    sections: {
      aboutTitle: "¿Quién es Aignis?",
      aboutContent:
        "Aignis es una plataforma de inteligencia artificial que, en lugar de generar respuestas basadas en información genérica de internet, utiliza tus propios datos para responder de forma precisa y personalizada. Puedes cargar toda tu base de conocimientos, desde documentos hasta archivos internos, y Aignis buscará respuestas exclusivamente en esa información. Esto te garantiza que cada respuesta esté adaptada a lo que necesitas saber, sin tener que buscar en cientos de páginas o bases de datos.",
      featuresTitle: "Características",
      featuresSubtitle: "Una mejor manera de gestionar tu conocimiento",
      featuresContent:
        "Aignis combina lo mejor de la IA con tu base de conocimientos para ofrecerte respuestas rápidas y fiables basadas en tu información.",
    },
    features: {
      feature1: {
        title: "Base de conocimientos personalizada",
        description:
          "Aignis se enfoca en utilizar únicamente tus datos, obteniendo información de tus documentos y de conexiones a tus bases de datos para responder a tus preguntas. Esto asegura que cada interacción esté completamente alineada con tu contenido e información. Ya no tendrás que preocuparte por obtener respuestas irrelevantes o genéricas, Aignis ofrece precisión porque se nutre directamente de tu conocimiento.",
      },
      feature2: {
        title: "Control total",
        description:
          "Tú decides qué información carga la IA, manteniendo siempre el control sobre tus datos. El plan avanzado tiene la opción de una instalación completamente local, donde tanto el modelo de IA como tu base de conocimientos residen en tus propios servidores. Así los datos nunca salen de tu organización, garantizando el máximo nivel de privacidad.",
      },
      feature3: {
        title: "Integración sencilla",
        description:
          "Gracias a nuestra <strong>API simple pero poderosa</strong>, podrás integrar Aignis rápidamente con tus sistemas existentes. Esto significa que puedes empezar a aprovechar todo el potencial de la inteligencia artificial sin necesidad de complejas implementaciones técnicas. Aignis estará lista para procesar y dar respuestas dentro de tus flujos de trabajo actuales.",
      },
      feature4: {
        title: "Capacidad escalable",
        description:
          "Nuestra infraestructura está diseñada para manejar grandes volúmenes de datos y consultas, garantizando respuestas rápidas sin importar el tamaño de tu organización. Además, nuestro sistema te permite añadir más capacidad cuando lo necesites, asegurando que tu base de conocimientos siempre esté disponible y actualizada.",
      },
    },
    pricing: {
      title: "Planes de precios",
      subtitle: "Elige el plan que mejor se adapte a tus necesidades",
      plans: {
        whatIncludes: "¿Qué incluye?",
        from: "Desde ",
        perMonth: " /mes",
        knowledgeBase: "Base de conocimiento de ",
        conversationTokens: " tokens al mes de conversación",
        knowledgeTokens: " tokens al mes de conocimiento",
        users: " usuarios",
        tier0: {
          name: "Chispa",
          description: "Para estudiantes y particulares",
          price: "Gratis",
          cta: "Registro gratis",
          includes: {
            knowledgeBase: "Base de conocimiento de 50 MB",
            conversationTokens: "500.000 tokens al mes *",
            users: "1 usuario",
            languageModel: "Modelo de lenguaje básico",
          },
        },
        tier1: {
          name: "Llama",
          description: "Para startups y profesionales",
          price: "29€",
          cta: "Contratar",
          includes: {
            knowledgeBase: "Base de conocimiento de 10 GB",
            conversationTokens: "6.000.000 tokens al mes *",
            languageModel: "Modelo de lenguaje extendido",
            users: "3 usuarios",
            support: "Soporte por email",
          },
        },
        tier2: {
          name: "Destello",
          description: "Para empresas en crecimiento",
          price: "149€",
          cta: "Contratar",
          includes: {
            knowledgeBase: "Base de conocimiento de 50 GB",
            conversationTokens: "30.000.000 tokens al mes *",
            languageModel: "Modelo de lenguaje avanzado",
            users: "20 usuarios",
            support: "Soporte prioritario por email",
            integration: "API de integración",
            databases: "Conexión a 1 base de datos",
          },
        },
        tier3: {
          name: "Fulgor",
          description: "Para grandes organizaciones",
          price: "749€",
          cta: "Hablemos",
          includes: {
            knowledgeBase: "Base de conocimiento de 250 GB",
            conversationTokens: "150.000.000 tokens al mes *",
            languageModel: "Modelo de lenguaje personalizado",
            users: "Sin límite de usuarios",
            support: "Soporte por agente dedicado",
            integration: "API de integración",
            databases: "Conexión a 5 bases de datos",
            localSetup: "Instalación local sin conexión externa ***",
          },
        },
      },
      notes:
        "<em>* 500.000 tokens equivalen aproximadamente a 375.000 palabras o 850 páginas. Por ejemplo, el libro ``El Quijote´´.</em><br><em>Los tokens se computan para todos tus usuarios tanto para las preguntas como para el contexto de tu base de conocimientos y las respuestas de la IA.</em> <br><br> <em>** Se conecta a tu base de datos para obtener información (SQL Server, MySql, PostgreSql, SQLite).</em> <br><br> <em>*** Requiere configuración adicional y puede tener costes adicionales.</em>",
    },
    contact: {
      title: "Contacto",
      subtitle: "¿Tienes alguna pregunta? Estamos aquí para ayudarte.",
      emailPrompt: "Envíanos un correo:",
      responseTime: "Te responderemos lo antes posible.",
    },
    footer: {
      terms: "Condiciones de uso",
      legalNotice: "Nota legal",
      privacyPolicy: "Política de privacidad",
      copyright: "Todos los derechos reservados.",
    },
  },
  signup: {
    meta: {
      title: "Regístrate en Aignis",
      description:
        "Regístrate en Aignis y comienza a disfrutar de todas las ventajas de la inteligencia artificial aplicada a tu base de conocimientos.",
    },
    title: "Regístrate en Aignis",
    name: "Nombre completo",
    namePlaceholder: "Tu nombre o el de tu organización",
    email: "Correo electrónico",
    emailPlaceholder: "Tu correo electrónico",
    address: "Dirección",
    addressPlaceholder: "Calle Ejemplo 42, piso 1",
    postalCode: "Código postal",
    postalCodePlaceholder: "Indica tu código postal",
    city: "Localidad",
    cityPlaceholder: "Indica tu localidad",
    country: "País",
    countryPlaceholder: "Indica tu país",
    phone: "Teléfono",
    phonePlaceholder: "Indica tu teléfono",
    vatNumber: "NIF / CIF",
    vatNumberPlaceholder: "Sólo si necesitas una factura",
    readConditions: "He leído y acepto las ",
    linkConditions: "condiciones de uso",
    readPrivacy: "He leído y acepto la ",
    linkPrivacy: "política de privacidad",
    cta: "Realizar registro",
    ctaing: "Realizando registro...",
    emailRegistered: "El correo electrónico ya está registrado",
    changeOrCancelAtAnyTime:
      "Al registrarte, crearás una suscripción a este plan. Puedes cambiar o cancelar tu suscripción en cualquier momento.",
    selectOtherPlan: "También puedes elegir otro plan:",
    countryNotValid: "Selecciona un país válido de la lista",
    acceptConditions: "Debes aceptar las condiciones de uso y la política de privacidad para continuar.",
  },
  successPage: {
    title: "¡Registro completado!",
    text: "Gracias por tu registro, tu cuenta está siendo creada. En pocos segundos <strong>recibirás un correo electrónico</strong> con tus credenciales de acceso.",
    credentialsReceived: "¿Ya has recibido tus credenciales?",
    cta: "Iniciar sesión",
  },
  cancelPage: {
    title: "Registro cancelado",
    text: "Tu registro ha sido cancelado. Si tienes alguna duda sobre el servicio o algún problema con tu registro, por favor, contacta con nosotros.",
    tryAgain: "¿Quieres volverlo a intentar?",
    cta: "Registro de nueva cuenta",
  },
  login: {
    meta: {
      title: "Inicia sesión en Aignis",
      description:
        "Accede a tu cuenta de Aignis y comienza a disfrutar de todas las ventajas de la inteligencia artificial aplicada a tu base de conocimientos.",
    },
    userName: "Correo electrónico",
    userNamePlaceholder: "Tu correo electrónico",
    password: "Contraseña",
    passwordPlaceholder: "Tu contraseña",
    remember: "Recordar sesión",
    cta: "Iniciar sesión",
    ctaing: "Iniciando sesión...",
    forgotPassword: "¿Has olvidado tu contraseña?",
    noAccount: "¿No tienes una cuenta?",
    signup: "Regístrate aquí",
    error: "Error",
    success: "Correcto",
    email: "Correo electrónico",
    emailPlaceholder: "Tu correo electrónico",
    resetPasswording: "Restableciendo contraseña...",
    resetPassword: "Restablecer contraseña",
    newPassword: "Nueva contraseña",
    newPasswordPlaceholder:
      "Min. 8 caracteres, 1 minúscula, 1 mayúscula, 1 número",
    notForgetPassword: "¿Ya recuerdas tu contraseña?",
    login: "Volver a iniciar sesión",
    passwordResetSent:
      "El link para resetear tu contraseña ha sido enviado a tu email.",
    passwordChanged:
      "Tu contraseña ha sido reseteada exitosamente. Serás redirigido a la página de inicio de sesión.",
  },
  chat: {
    meta: {
      title: "Chat con Aignis",
      description:
        "Habla con Aignis, tu asistente virtual, y obtén respuestas precisas y relevantes en segundos.",
    },
    conversations: "Conversaciones",
    newConversation: "Iniciar nueva conversación",
    confirmDeleteConversation:
      "¿Estás seguro de que quieres eliminar esta conversación?",
    cancel: "Cancelar",
    delete: "Eliminar",
    title: "Chat con Aignis",
    menuTitle: "Menú",
    menu: {
      knowledge: "Base de conocimiento",
      settings: "Configuración",
      yourUser: "Tu usuario",
      userManagement: "Gestión de usuarios",
      accountManagement: "Gestión de la cuenta",
      accountUsage: "Uso de la cuenta",
      pricingPlans: "Planes de precios",
      AIproviders: "Proveedores de IA",
      logout: "Cerrar sesión",
      language: "Idioma",
    },
    copyToClipboard: "Copiar al portapapeles",
    copyCode: "copiar código",
    writeYourMessage: "Escribe tu mensaje...",
    disclaimer:
      "Aignis puede cometer errores, verifica la información importante.",
    terms: "Condiciones de uso",
    thinking: "Aignis está pensando...",
    writing: "Aignis está escribiendo...",
    welcome: "Hola, soy **Aignis**, ¿en qué te puedo ayudar hoy?",
    welcomeAgain: "¡Hola de nuevo! ¿Qué hacemos ahora?",
    requiresDatabaseQuery: "Enviar consulta a la base de datos",
    selectDatabase: "Selecciona la base de datos",
  },
  accountManagement: {
    title: "Gestión de la cuenta",
    accountData: "Datos de tu cuenta",
    name: "Nombre de la cuenta",
    namePlaceholder: "Tu nombre o el de tu organización",
    address: "Dirección",
    addressPlaceholder: "Calle Ejemplo 42, piso 1",
    postalCode: "Código postal",
    postalCodePlaceholder: "Indica tu código postal",
    city: "Localidad",
    cityPlaceholder: "Indica tu localidad",
    country: "País",
    countryPlaceholder: "Indica tu país",
    phone: "Teléfono",
    phonePlaceholder: "Indica tu teléfono",
    email: "Correo electrónico",
    emailPlaceholder: "Tu correo electrónico",
    vatNumber: "NIF / CIF",
    vatNumberPlaceholder: "Sólo si necesitas una factura",
    save: "Guardar cambios",
    planData: "Datos de tu plan",
    users: "Usuarios: ",
    knowledgeBase: "Base de conocimiento: ",
    knowledgeTokens: "Tokens de conocimiento: ",
    perMonth: " al mes",
    conversationTokens: "Tokens de conversación: ",
    subscriptionData: "Datos de tu suscripción",
    subscriptionStatus: "Estado de la suscripción: ",
    active: "Activa",
    inactive: "Inactiva",
    amount: "Importe: ",
    lastPayment: "Último pago: ",
    nextPayment: "Próximo pago: ",
    willCancel: "Se cancelará el ",
    manageSubscription: "Gestionar tu suscripción",
    strictRag: "Activar modo estricto",
    strictRagExplanation:
      "Al activar el modo estricto, Aignis solo responderá a las preguntas que se encuentren en tu base de conocimiento. Si la pregunta no se puede responder con la información que has subido, Aignis no dará una respuesta.",
  },
  accountUsage: {
    title: "Uso de la cuenta",
    subTitle: "Datos de uso por mes",
    year: "Año: ",
    loadingData: "Cargando datos...",
    users: "Usuarios",
    knowledgeBase: "Base de conocimiento",
    knowledgeTokens: "Tokens de conocimiento",
    conversationTokens: "Tokens de conversación",
    noDataForPeriod: "No hay datos para el periodo seleccionado.",
    noPermission: "No tienes permisos para ver esta información.",
    noLimit: "Sin límite",
  },
  usersManagement: {
    title: "Gestión de usuarios",
    explanation:
      "Puedes añadir usuarios con rol ADMIN o USUARIO. El Admin puede gestionar archivos, datos de la cuenta, y crear o eliminar usuarios. El Usuario puede interactuar con la IA que tendrá los conocimientos de tus documentos, pero no puede gestionar los archivos, ni gestionar otros usuarios.",
    add: "Añadir ",
    edit: "Editar ",
    user: "Usuario",
    admin: "Admin",
    addUser: "Añadir usuario",
    userName: "Nombre",
    userNamePlaceholder: "Nombre del usuario",
    email: "Email",
    emailPlaceholder: "Correo electrónico del usuario",
    roles: "Rol",
    access: "Acceso",
    active: "Activo",
    inactive: "Inactivo",
    lastAccess: "Último acceso",
    noUsers: "No hay otros usuarios en la cuenta.",
    userNamePlaceholder: "Nombre del usuario",
    password: "Contraseña",
    passwordPlaceholderLeaveBlank: "Dejar en blanco para mantener la actual",
    passwordPlaceholderRequirements:
      "Min. 8 caracteres, 1 minúscula, 1 mayúscula, 1 número",
    noAllowAccess: "No permitir el acceso a este usuario",
    deleteConfirmation: "¿Estás seguro de que quieres eliminar este usuario?",
    cancel: "Cancelar",
    delete: "Eliminar",
  },
  knowledgeBase: {
    title: "Base de conocimiento",
    uploading: "Añadiendo archivos a la base de conocimiento de la IA",
    explanation:
      "Sube archivos relevantes y conecta tus bases de datos a la base de conocimientos de Aignis para que la IA pueda ofrecer respuestas más precisas y útiles, adaptadas a la información específica de tu organización. Cuantos más recursos proporciones, ya sean archivos o bases de datos, mayor será la capacidad de Aignis para comprender y procesar tus datos, brindándote respuestas completas y ajustadas a tus necesidades.",
    uploadExplanation:
      "Los archivos que subas se almacenarán encriptados y no podrán ser descargados. Solo fragmentos relevantes de los documentos serán enviados al modelo de lenguaje natural para responder a las preguntas de los usuarios, pero estos fragmentos no serán almacenados ni utilizados para entrenar el modelo.",
    databaseExplanation:
      "Las conexiones a bases de datos que añadas se mantendrán encriptadas y seguras, y los datos consultados no se almacenarán localmente. Solo los fragmentos relevantes de la información necesaria serán enviados al modelo de lenguaje natural para responder a las preguntas de los usuarios, pero estos fragmentos no serán almacenados ni utilizados para entrenar el modelo.",
    close: "Cerrar",
    addDocuments: "Añadir documentos",
    dragFilesOrClick:
      "Arrastra los archivos aquí o haz click para seleccionarlos",
    upload: "Subir archivos",
    uploading: "Subiendo archivos...",
    existingFiles: "Documentos",
    name: "Nombre",
    size: "Tamaño",
    creationDate: "Fecha de creación",
    noFiles:
      "No hay archivos en la base de conocimiento, haz click en el botón para añadir tus archivos.",
    file: "Archivo ",
    uploadedOk: " subido correctamente",
    uploadedError: "Error al subir el archivo ",
    allOk: "Todos los archivos subidos correctamente",
    someKo: "Algunos archivos no se han podido subir",
    deletedOk: "Archivo eliminado correctamente",
    deletedError: "Error al eliminar el archivo",
    fetchFilesError: "Error al obtener los archivos de la base de conocimiento",
    dbConnections: "Conexiones a bases de datos",
    addDbConnection: "Añadir conexión a base de datos",
    editDbConnection: "Editar conexión a base de datos",
    provider: "Proveedor de base de datos",
    databaseProviders: {
      SqlServer: "Microsoft SQL Server",
      MySql: "MySQL / MariaDB",
      PostgreSql: "PostgreSQL",
      SQLite: "SQLite",
    },
    connectionName: "Nombre de la conexión",
    connectionNamePlaceholder: "Nombre interno para identificar la conexión",
    connectionString: "Cadena de conexión (Connection String)",
    connectionStringPlaceholder:
      "Server=myServerAddress;Database=myDataBase;User Id=myUsername;Password=myPassword;",
  },
  userProfile: {
    title: "Tu usuario",
    name: "Nombre de usuario",
    namePlaceholder: "Tu nombre",
    email: "Correo electrónico",
    emailPlaceholder: "Tu correo electrónico",
    password: "Contraseña",
    passwordPlaceholder: "Dejar en blanco para mantener la actual",
    save: "Guardar cambios",
    passwordChanged: "Tu contraseña ha sido cambiada correctamente.",
    lastFailedLogin: "Último intento de acceso fallido: ",
    getDataError: "Error al obtener los datos del usuario",
  },
};
