<template>
  <div class="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col md:flex-row">
      <!-- Signup Form -->
      <div class="w-full md:w-2/3 p-6 md:p-8">
        <div class="flex items-center justify-between mb-6">
          <div class="flex items-center space-x-2">
            <img src="@/assets/img/logo-inverted.svg" alt="Aignis" class="h-8 w-auto" />
            <h1 class="text-xl font-bold text-gray-800 text-left">{{ $t('signup.title') }}</h1>
          </div>
          <div class="flex space-x-2">
            <button @click="changeLanguage('en')"
              class="text-xs px-3 py-1.5 bg-indigo-100 text-indigo-700 rounded-full hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-300 font-medium">
              EN
            </button>
            <button @click="changeLanguage('es')"
              class="text-xs px-3 py-1.5 bg-indigo-100 text-indigo-700 rounded-full hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-300 font-medium">
              ES
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSignup" class="space-y-4">
          <div class="grid grid-cols-1 gap-4">
            <div class="relative">
              <label for="name" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.name') }}
              </label>
              <input v-model="formData.name" id="name" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.namePlaceholder')" />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="relative">
              <label for="email" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.email') }}
              </label>
              <input v-model="formData.email" id="email" type="email" required :class="{'border-red-500': emailExists}"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.emailPlaceholder')" />
              <p v-if="emailExists" class="text-red-500 text-sm mt-1">{{ $t('signup.emailRegistered') }}</p>
            </div>
            <div class="relative">
              <label for="phone" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.phone') }}
              </label>
              <input v-model="formData.phone" id="phone" type="tel" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.phonePlaceholder')" />
            </div>
            <div class="relative">
              <label for="address" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.address') }}
              </label>
              <input v-model="formData.address" id="address" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.addressPlaceholder')" />
            </div>
            <div class="relative">
              <label for="postalCode" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.postalCode') }}
              </label>
              <input v-model="formData.postalCode" id="postalCode" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.postalCodePlaceholder')" />
            </div>
            <div class="relative">
              <label for="city" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.city') }}
              </label>
              <input v-model="formData.city" id="city" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('signup.cityPlaceholder')" />
            </div>

            <div class="relative">
              <label for="country" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('signup.country') }}
              </label>
              <div class="flex items-center">
                <!-- Campo para selección del país con el nombre, pero enviando el código ISO -->
                <input v-model="formData.country" @input="filterCountries" @blur="validateCountry" id="country"
                  type="text" required :class="{'border-red-500': countryError}"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('signup.countryPlaceholder')" autocomplete="off" />
              </div>
              <ul v-if="filteredCountries.length > 0"
                class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 w-full overflow-auto">
                <li v-for="country in filteredCountries" :key="country.code" @click="selectCountry(country)"
                  class="px-4 py-2 cursor-pointer hover:bg-indigo-100 flex items-center">
                  {{ country.name }}
                </li>
              </ul>
              <p v-if="countryError" class="text-red-500 text-sm mt-2">{{ countryError }}</p>
            </div>
          </div>

          <!-- Terms and Conditions -->
          <div class="space-y-4 mt-6">
            <label class="flex items-center mt-2">
              <input v-model="formData.acceptTerms" type="checkbox" required
                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
              <span class="ml-2 text-sm text-gray-600">
                {{ $t('signup.readConditions') }}
                <a href="#" @click.prevent="toggleContent('terms')" class="text-indigo-600 hover:underline">{{
                  $t('signup.linkConditions') }}</a>
              </span>
            </label>

            <transition name="slide-fade">
              <div v-if="showTerms"
                class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-sm resize-y overflow-auto text-left text-gray-500"
                v-html="termsOfService" style="max-height: 7rem;">
              </div>
            </transition>

            <label class="flex items-center mt-2">
              <input v-model="formData.acceptPrivacyPolicy" type="checkbox" required
                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
              <span class="ml-2 text-sm text-gray-600">
                {{ $t('signup.readPrivacy') }}
                <a href="#" @click.prevent="toggleContent('privacy')" class="text-indigo-600 hover:underline">{{
                  $t('signup.linkPrivacy') }}</a>
              </span>
            </label>

            <transition name="slide-fade">
              <div v-if="showPrivacy"
                class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-sm resize-y overflow-auto text-left text-gray-500"
                v-html="privacyPolicy" style="max-height: 7rem;">
              </div>
            </transition>
          </div>

          <!-- Signup Button -->
          <div class="mt-6">
            <button type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :disabled="isLoading || emailExists">
              {{ isLoading ? $t('signup.ctaing') : $t('signup.cta') }}
            </button>
          </div>
        </form>
      </div>

      <!-- Plan Details -->
      <div class="w-full md:w-1/3 bg-gray-50 p-6 md:p-8 text-center">
        <!-- <p class="text-md font-semibold mb-4">Tu plan:</p> -->

        <div v-if="selectedPlan.name === 'Chispa'">
          <div class="flex justify-center mb-4">
            <Flame class="h-8 w-8 text-indigo-600" />
          </div>
          <h3 class="text-2xl font-semibold mb-4">{{ $t('home.pricing.plans.tier0.name') }}</h3>
          <ul class="space-y-2 mb-4">
            <li v-for="feature in selectedPlan.features" :key="feature" class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ feature }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier0.includes.languageModel') }}
            </li>
          </ul>
        </div>

        <div v-if="selectedPlan.name === 'Llama'">
          <div class="flex justify-center mb-4">
            <Flame class="h-8 w-8 text-indigo-500" />
            <Flame class="h-8 w-8 text-indigo-600" />
          </div>
          <h3 class="text-2xl font-semibold mb-4">{{ $t('home.pricing.plans.tier1.name') }}</h3>
          <ul class="space-y-2 mb-4">
            <li v-for="feature in selectedPlan.features" :key="feature" class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ feature }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier1.includes.languageModel') }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier1.includes.support') }}
            </li>
          </ul>
        </div>

        <div v-if="selectedPlan.name === 'Destello'">
          <div class="flex justify-center mb-4">
            <Flame class="h-8 w-8 text-indigo-400" />
            <Flame class="h-8 w-8 text-indigo-500" />
            <Flame class="h-8 w-8 text-indigo-600" />
          </div>
          <h3 class="text-2xl font-semibold mb-4">{{ $t('home.pricing.plans.tier2.name') }}</h3>
          <ul class="space-y-2 mb-4">
            <li v-for="feature in selectedPlan.features" :key="feature" class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ feature }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier2.includes.languageModel') }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier2.includes.support') }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier2.includes.integration') }}
            </li>
            <li class="flex items-center text-left text-xs">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
              </svg>
              {{ $t('home.pricing.plans.tier2.includes.databases') }}
            </li>
          </ul>
        </div>

        <p class="text-4xl font-extrabold text-gray-900">{{ selectedPlan.price === 0 ?
          $t('home.pricing.plans.tier0.price') : selectedPlan.price+"€"
          }}
          <span v-if="selectedPlan.price != 0" class="text-base font-medium text-gray-500"> {{
            $t('home.pricing.plans.perMonth') }}</span>
        </p>
        <p class="mt-4 text-sm text-gray-600 text-xs">{{ $t('signup.changeOrCancelAtAnyTime') }}</p>

        <!-- Plan Selector -->
        <div class="mt-10">
          <h4 class="text-lg font-semibold mb-2">{{ $t('signup.selectOtherPlan') }}</h4>
          <div class="flex flex-wrap gap-2">
            <button v-for="plan in pricingPlans" :key="plan.id" @click="selectPlan(plan)"
              class="px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ease-in-out"
              :class="plan.id === selectedPlan.id ? 'bg-gray-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'">
              {{ plan.planTranslated }} - {{ plan.price }}€ {{ $t('home.pricing.plans.perMonth') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, reactive, watch, onMounted, computed } from 'vue'
import { checkEmailExists, createCheckoutSession, getPricingPlanPublicList } from '../services/api';
import { useHead } from '@vueuse/head'
import { useRouter, useRoute } from 'vue-router'
import { Flame } from 'lucide-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useI18n } from 'vue-i18n';
import { useNotification } from '@/plugins/notificationPlugin';

const { t, locale } = useI18n();
const { showSuccess, showError } = useNotification();

const pageTitle = computed(() => t('signup.meta.title'));
const pageDescription = computed(() => t('signup.meta.description'));

useHead({
title: pageTitle.value,
meta: [
  { name: 'description', content: pageDescription.value },
  { property: 'og:title', content: pageTitle.value },
  { property: 'og:description', content: pageDescription.value },
  { name: 'twitter:title', content: pageTitle.value },
  { name: 'twitter:description', content: pageDescription.value },
],
});

const route = useRoute()

const pricingPlans = ref([])
const selectedPlan = ref({
  id: null,
  name: '',
  price: 0,
  features: []
})

const formData = reactive({
  name: '',
  email: '',
  password: '',
  address: '',
  postalCode: '',
  city: '',
  country: '', 
  countryCode: '', 
  phone: '',
  acceptPrivacyPolicy: false,
  acceptTerms: false
})

const emailExists = ref(false)
let debounceTimeout = null
const checkEmailAvailability = async () => {
  if (!formData.email) {
    emailExists.value = false;
    return;
  }

  try {
    const response = await checkEmailExists(formData.email);
    emailExists.value = false;
  } catch (error) {
    if (error.status === 409) {
      emailExists.value = true;
      showError(error.detail || t('signup.emailRegistered'));
    } else {
      showError(error.detail || t('genericError'));
    }
  }
};

// Observar cambios en el campo de email
watch(
  () => formData.email,
  () => {
    if (debounceTimeout) clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => {
      checkEmailAvailability()
    }, 500) // Espera de 500ms antes de llamar al API
  }
)

const isLoading = ref(false)

const fetchPricingPlans = async () => {
  try {
    const allPlans = await getPricingPlanPublicList();
    pricingPlans.value = allPlans.data
      .filter(plan => plan.contractable)
      .map(plan => {
        // Agrega el campo planTranslated basándose en el nombre en español del plan
        let translatedName;
        switch (plan.name) {
          case 'Chispa':
            translatedName = locale.value === 'en' ? 'Spark' : 'Chispa';
            break;
          case 'Llama':
            translatedName = locale.value === 'en' ? 'Flame' : 'Llama';
            break;
          case 'Destello':
            translatedName = locale.value === 'en' ? 'Glow' : 'Destello';
            break;
          case 'Fulgor':
            translatedName = locale.value === 'en' ? 'Radiance' : 'Fulgor';
            break;
          default:
            translatedName = plan.name;
        }

        return {
          ...plan,
          planTranslated: translatedName
        };
      });

    const planId = Number(route.query.planId);
    const preselectedPlan = pricingPlans.value.find(plan => plan.id === planId);

    if (preselectedPlan) {
      selectPlan(preselectedPlan);
    } else if (pricingPlans.value.length > 0) {
      selectPlan(pricingPlans.value[0]);
    }
  } catch (error) {
  }
};

const filteredCountries = ref([]);
const allCountries = ref([]);
const countryError = ref("");

async function loadCountries() {
  const response = await fetch("https://restcountries.com/v3.1/all");
  const countries = await response.json();
  
  allCountries.value = countries.map((country) => {
    // Carga el nombre en español solo si el idioma actual es 'es'
    const countryName = locale.value === 'es'
      ? country.translations?.spa?.common || country.name.common
      : country.name.common;

      return {
      name: countryName,
      code: country.cca2 // Código ISO de dos letras
    };
  }).sort((a, b) => a.name.localeCompare(b.name));
}

const filterCountries = () => {
  const query = formData.country.toLowerCase();
  filteredCountries.value = allCountries.value.filter((country) =>
    country.name.toLowerCase().includes(query)
  );
  countryError.value = "";
};

const selectCountry = (country) => {
  formData.country = country.name;
  formData.countryCode = country.code;
  filteredCountries.value = [];
  countryError.value = "";
};

// Función de validación
const validateCountry = () => {
  const country = allCountries.value.find(
    (c) => c.name.toLowerCase() === formData.country.toLowerCase()
  );
  if (country) {
    filteredCountries.value = []; 
    countryError.value = ""; 
  } else {
    countryError.value = t('signup.countryNotValid'); 
    formData.country = ""; 
  }
};

const selectPlan = (plan) => {
  selectedPlan.value = {
    id: plan.id,
    name: plan.name,
    price: plan.price,
    features: [
      `${t('home.pricing.plans.knowledgeBase')} ${formatBytes(plan.maxDiskUsageInBytes)}`,
      `${formatNumber(plan.maxTokensPerMonth)} ${t('home.pricing.plans.conversationTokens')}`,
      `${formatNumber(plan.maxEmbeddingTokensPerMonth)} ${t('home.pricing.plans.knowledgeTokens')}`,
      `${plan.maxUsers} ${t('home.pricing.plans.users')}`,
    ]
  }
}

const handleSignup = async () => {
  if (emailExists.value) {
    showError(t('signup.emailRegistered'));
    return;
  }

  if (!formData.acceptPrivacyPolicy || !formData.acceptTerms) {
    showError(t('signup.acceptConditions'));
    return;
  }

  isLoading.value = true;

  try {
    const response = await createCheckoutSession({
      planName: selectedPlan.value.name,
      price: selectedPlan.value.price,
      name: formData.name,
      email: formData.email,
      address: formData.address,
      postalCode: formData.postalCode,
      city: formData.city,
      country: formData.countryCode,
      phoneNumber: formData.phone,
      pricingPlanId: selectedPlan.value.id,
    });

    showSuccess(t('signup.redirecting'));
    window.location.href = response.data.url;
  } catch (error) {
    showError(error.detail || t('genericError'));
  } finally {
    isLoading.value = false;
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

onMounted(() => {
  loadCountries();
  fetchPricingPlans()
  loadConditions()
  loadPrivacy()
  toggleContent('terms')
})

const showTerms = ref(false);
const showPrivacy = ref(false);

const toggleContent = (type) => {
    if (type === 'terms') {
        showTerms.value = !showTerms.value;
    } else if (type === 'privacy') {
        showPrivacy.value = !showPrivacy.value;
    }
};

const termsOfService = ref('');
const loadConditions = async () => {
  try {
    const response = await fetch(`/legal/conditions-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      termsOfService.value = DOMPurify.sanitize(marked(rawContent));
    } else {
        termsOfService.value = 'No se pudo cargar las condiciones de uso.';
    }
  } catch (error) {
    termsOfService.value = 'Error al cargar las condiciones de uso.';
  }
};

const privacyPolicy = ref('');
const loadPrivacy = async () => {
  try {
    const response = await fetch(`/legal/privacy-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      privacyPolicy.value = DOMPurify.sanitize(marked(rawContent));
    } else {
        privacyPolicy.value = 'No se pudo cargar la nota legal.';
    }
  } catch (error) {
    privacyPolicy.value = 'Error al cargar la nota legal.';
  }
};

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}
</script>