<template>
  <div class="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col items-center justify-center p-4 mobile-scroll-lock">
    <div class="w-full max-w-md">
      <transition name="fade">
        <div v-if="error"
          class="mb-4 bg-red-100 border-b-4 border-red-500 text-red-700 px-4 py-3 rounded-md shadow-md w-full"
          role="alert">
          <div class="flex items-start justify-between">
            <div class="py-1 pr-8">
              <p class="font-bold">{{ $t('login.error') }}</p>
              <p class="text-sm">{{ error }}</p>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="message"
          class="mb-4 bg-green-100 border-b-4 border-green-500 text-green-700 px-4 py-3 rounded-md shadow-md w-full"
          role="alert">
          <div class="flex items-start justify-between">
            <div class="py-1 pr-8">
              <p class="font-bold">{{ $t('login.success') }}</p>
              <p class="text-sm">{{ message }}</p>
            </div>
          </div>
        </div>
      </transition>

      <div class="bg-white rounded-lg shadow-xl overflow-hidden">
        <!-- Cabecera del formulario -->
        <div class="bg-indigo-600 p-5 text-white">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <img src="@/assets/img/logo.svg" alt="Aignis" class="h-8 w-auto" />
              <h1 class="text-xl font-bold">{{ $t('appName') }}</h1>
            </div>
            <!-- Botones de selección de idioma -->
            <div class="flex space-x-2">
              <button @click="changeLanguage('en')"
                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                EN
              </button>
              <button @click="changeLanguage('es')"
                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                ES
              </button>
            </div>
          </div>
        </div>
        <div class="p-6 md:p-8">
          <form @submit.prevent="handleSubmit" class="space-y-6">
            <div v-if="!token">
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700 text-left" for="email">{{ $t('login.email') }}</label>
                <input v-model="email" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500" id="email" type="email"
                  :placeholder="$t('login.emailPlaceholder')" required />
              </div>
              <button
                class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit" :disabled="isLoading">
                {{ isLoading ? $t('login.resetPasswording') : $t('login.resetPassword') }}
              </button>
            </div>
            <div v-else>
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700 text-left" for="newPassword">{{ $t('login.newPassword') }}</label>
                <input v-model="newPassword" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500" id="newPassword"
                  type="password" :placeholder="$t('login.newPasswordPlaceholder')" required />
              </div>
              <button
                class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit" :disabled="isLoading">
                {{ isLoading ? $t('login.resetPasswording') : $t('login.resetPassword') }}
              </button>
            </div>
          </form>
        </div>
        <div
          class="px-6 md:px-8 py-4 bg-gray-50 border-t border-gray-100 flex flex-col sm:flex-row justify-between items-center">
          <p class="text-xs text-gray-500">{{ $t('login.notForgetPassword') }}</p>
          <a href="/login" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">{{ $t('login.login') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { requestPasswordReset, resetPassword } from '../services/api';
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}

const pageTitle = computed(() => t('login.meta.title'));
const pageDescription = computed(() => t('login.meta.description'));

useHead({
  title: pageTitle.value,
  meta: [
    {
      name: 'description',
      content: pageDescription.value,
    },
    {
      property: 'og:title',
      content: pageTitle.value,
    },
    {
      property: 'og:description',
      content: pageDescription.value,
    },
    {
      name: 'twitter:title',
      content: pageTitle.value,
    },
    {
      name: 'twitter:description',
      content: pageDescription.value,
    },
  ],
});

const route = useRoute();
const router = useRouter();

const email = ref('');
const newPassword = ref('');
const token = ref('');
const error = ref('');
const message = ref('');
const isLoading = ref(false);

onMounted(() => {
  token.value = route.query.token || '';
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });
});

const handleSubmit = async () => {
  isLoading.value = true;
  error.value = '';
  message.value = '';

  try {
    if (!token.value) {
      await requestPasswordReset(email.value);
      message.value = t('login.passwordResetSent');
    } else {
      await resetPassword(token.value, newPassword.value);
      message.value = t('login.passwordChanged');
      setTimeout(() => router.push('/login'), 6000);
    }
  } catch (err) {
    error.value = err.detail || t('genericError');
  } finally {
    isLoading.value = false;
  }
};

const clearError = () => {
  error.value = '';
};

const clearMessage = () => {
  message.value = '';
};
</script>
