export const setUserInfo = (userInfo) => {
  localStorage.setItem('userId', userInfo.userId);
  localStorage.setItem('accountId', userInfo.accountId);
  localStorage.setItem('username', userInfo.username);
  localStorage.setItem('roles', JSON.stringify(userInfo.roles));
  localStorage.setItem('expiration', userInfo.expiration);
};

export const clearUserInfo = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('accountId');
  localStorage.removeItem('username');
  localStorage.removeItem('roles');
  localStorage.removeItem('expiration');
};

export const getCurrentUserRoles = () => {
  const roles = localStorage.getItem('roles');
  return roles ? JSON.parse(roles) : [];
};

export const hasRole = (role) => {
  const roles = getCurrentUserRoles();
  return roles.includes(role);
};

// Si hay user está autenticado
export const isAuthenticated = () => {
  return !!localStorage.getItem('userId');
};
