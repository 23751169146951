<template>
  <div
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">{{ $t('accountManagement.title') }}</h3>
        </div>
        <button @click="$emit('close')"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>
      <div class="p-6 bg-gray-50 overflow-y-auto" style="max-height: 70vh;">
        <!-- Botón para crear cuenta (solo visible para SysAdmin) -->
        <div v-if="isSysAdmin" class="mb-4 flex justify-end">
          <button @click="showAccountForm = true"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md">
            Crear cuenta
          </button>
        </div>

        <!-- Lista de cuentas (solo visible para SysAdmin) -->
        <div v-if="isSysAdmin" class="mb-8">
          <h4 class="text-lg font-semibold mb-4">Lista de cuentas</h4>
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-indigo-50">
                <tr>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acceso</th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suscripción
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Renovación
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="account in accounts" :key="account.id"
                  :class="{ 'bg-gray-100 text-gray-500': account.disabled }">
                  <td class="px-6 py-4 whitespace-nowrap text-left">{{ account.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-left">
                    <span :class="account.disabled ? 'text-red-600' : 'text-green-600'">
                      {{ account.disabled ? 'Inactivo' : 'Activo' }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-left">{{ account.pricingPlan.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-left">
                    <span :class="account.accountPayments?.isSubscriptionActive ? 'text-green-600' : 'text-red-600'">
                      {{ account.accountPayments?.isSubscriptionActive ? 'Activa' : 'Inactiva' }}
                    </span>
                    <span class="text-gray-400 text-sm ml-1">{{ account.accountPayments?.providerName }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-left">
                    <span v-if="account.accountPayments?.cancelAtDate != null" class="text-red-600">
                      {{ formatDate(account.accountPayments?.nextBillingDate) }}</span>
                    <span v-else>{{ formatDate(account.accountPayments?.nextBillingDate) }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button @click="editAccount(account)" class="text-indigo-600 hover:text-indigo-900 mr-2">
                      <Edit2Icon class="h-5 w-5" />
                    </button>
                    <button @click="deleteAccount(account.id)" class="text-red-600 hover:text-red-900 mr-2">
                      <TrashIcon class="h-5 w-5" />
                    </button>
                    <button v-if="isSysAdmin" @click="openPaymentPopup(account.id)"
                      class="text-green-600 hover:text-green-900 mr-2">
                      <DollarSignIcon class="h-5 w-5" />
                    </button>
                    <button v-if="isSysAdmin" @click="disablePaymentAction(account.id)"
                      class="text-yellow-600 hover:text-yellow-900">
                      <BanIcon class="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="flex flex-col md:flex-row md:space-x-6">

          <div class="w-full md:w-1/2 space-y-4">

            <!-- Formulario de edición de cuenta actual -->
            <h4 class="text-lg font-semibold mb-4">{{ $t('accountManagement.accountData') }}</h4>


            <form @submit.prevent="submitForm" class="space-y-4">
              <div class="relative">
                <label for="accountName" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.name') }}
                </label>
                <input type="text" id="accountName" v-model="accountData.name" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.namePlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountAddress"
                  class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.address') }}
                </label>
                <input type="text" id="accountAddress" v-model="accountData.address" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.addressPlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountPostalCode"
                  class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.postalCode') }}
                </label>
                <input type="text" id="accountPostalCode" v-model="accountData.postalCode" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.postalCodePlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountCity" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.city') }}
                </label>
                <input type="text" id="accountCity" v-model="accountData.city" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.cityPlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountCountry"
                  class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.country') }}
                </label>
                <input type="text" id="accountCountry" v-model="accountData.country" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.countryPlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountPhoneNumber"
                  class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.phone') }}
                </label>
                <input type="tel" id="accountPhoneNumber" v-model="accountData.phoneNumber" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.phonePlaceholder')" required />
              </div>
              <div class="relative">
                <label for="accountEmail" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.email') }}
                </label>
                <input type="email" id="accountEmail" v-model="accountData.email" :disabled="isStripeProvider"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.emailPlaceholder')" required />
              </div>
              <div v-if="isSysAdmin" class="relative">
                <label for="accountVatNumber"
                  class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  {{ $t('accountManagement.vatNumber') }}
                </label>
                <input type="text" id="accountVatNumber" v-model="accountData.vatNumber"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  :placeholder="$t('accountManagement.vatNumberPlaceholder')" />
              </div>
              <div class="relative">
                <label for="strictRag" class="flex items-center space-x-2">
                  <input type="checkbox" id="strictRag" v-model="accountData.strictRag"
                    class="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out" />
                  <span class="text-sm text-gray-700">{{ $t('accountManagement.strictRag') }}</span>
                  <div class="relative">
                    <HelpCircleIcon class="h-5 w-5 text-gray-400 cursor-help" @mouseenter="showTooltip = true"
                      @mouseleave="showTooltip = false" />
                    <div v-if="showTooltip"
                      class="absolute z-10 p-2 bg-gray-800 text-white text-xs rounded shadow-lg mt-1 -left-1/2 w-48 strictRagTooltip">
                      {{ $t('accountManagement.strictRagExplanation') }}
                    </div>
                  </div>
                </label>
              </div>
              <div v-if="isSysAdmin" class="relative">
                <label for="pricingPlan" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                  Plan de precios
                </label>
                <select id="pricingPlan" v-model="accountData.pricingPlanId"
                  class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500">
                  <option v-for="plan in pricingPlans" :key="plan.id" :value="plan.id">{{ plan.name }}</option>
                </select>
              </div>
              <div class="flex items-center justify-center pt-4">
                <button type="submit"
                  class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                  {{ $t('accountManagement.save') }}
                </button>
              </div>
            </form>
          </div>

          <!-- Right column: Payment information -->
          <div class="w-full md:w-1/2 space-y-4 mt-6 md:mt-0">
            <h4 class="text-lg font-semibold mb-4">{{ $t('accountManagement.planData') }}</h4>
            <div class="bg-white p-6 rounded-lg shadow-md">
              <div v-if="accountData.plan" class="space-y-3">
                <div v-if="accountData.plan.name === 'Chispa'" class="flex flex-col items-center mb-4">
                  <div class="flex justify-center mb-2">
                    <Flame class="h-8 w-8 text-indigo-600" />
                  </div>
                  <h3 class="text-2xl font-semibold">{{ $t('home.pricing.plans.tier0.name') }}</h3>
                </div>
                <div v-if="accountData.plan.name === 'Llama'" class="flex flex-col items-center mb-4">
                  <div class="flex justify-center mb-2">
                    <Flame class="h-8 w-8 text-indigo-500" />
                    <Flame class="h-8 w-8 text-indigo-600" />
                  </div>
                  <h3 class="text-2xl font-semibold">{{ $t('home.pricing.plans.tier1.name') }}</h3>
                </div>
                <div v-if="accountData.plan.name === 'Destello'" class="flex flex-col items-center mb-4">
                  <div class="flex justify-center mb-2">
                    <Flame class="h-8 w-8 text-indigo-400" />
                    <Flame class="h-8 w-8 text-indigo-500" />
                    <Flame class="h-8 w-8 text-indigo-600" />
                  </div>
                  <h3 class="text-2xl font-semibold">{{ $t('home.pricing.plans.tier2.name') }}</h3>
                </div>
                <div v-if="accountData.plan.name === 'Fulgor'" class="flex flex-col items-center mb-4">
                  <div class="flex justify-center mb-2">
                    <Flame class="h-8 w-8 text-indigo-400" />
                    <Flame class="h-8 w-8 text-indigo-500" />
                    <Flame class="h-8 w-8 text-indigo-600" />
                    <Flame class="h-8 w-8 text-indigo-700" />
                  </div>
                  <h3 class="text-2xl font-semibold">{{ $t('home.pricing.plans.tier3.name') }}</h3>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.users') }}</span>
                  <span>{{ accountData.plan.maxUsers }}</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.knowledgeBase') }}</span>
                  <span>{{ formatBytes(accountData.plan.maxDiskUsageInBytes) }}</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.knowledgeTokens') }}</span>
                  <span>{{ accountData.plan.maxEmbeddingTokensPerMonth.toLocaleString() }} {{
                    $t('accountManagement.perMonth') }}</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.conversationTokens') }}</span>
                  <span>{{ accountData.plan.maxTokensPerMonth.toLocaleString() }} {{ $t('accountManagement.perMonth')
                    }}</span>
                </div>
              </div>
            </div>

            <h4 class="text-lg font-semibold mb-4">{{ $t('accountManagement.subscriptionData') }}</h4>
            <div class="bg-white p-6 rounded-lg shadow-md">
              <div v-if="accountData.accountPayments" class="space-y-3">
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.subscriptionStatus') }}</span>
                  <span :class="accountData.accountPayments.isSubscriptionActive ? 'text-green-600' : 'text-red-600'">
                    {{ accountData.accountPayments.isSubscriptionActive ? $t('accountManagement.active') :
                    $t('accountManagement.inactive') }}
                  </span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.amount') }}</span>
                  <span>{{ accountData.accountPayments.amount }} €</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.lastPayment') }}</span>
                  <span>{{ formatDate(accountData.accountPayments.lastPaymentDate) }}</span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-gray-600">{{ $t('accountManagement.nextPayment') }}</span>
                  <span v-if="accountData.accountPayments.cancelAtDate != null" class="text-red-600">{{
                    $t('accountManagement.willCancel') }} {{
                    formatDate(accountData.accountPayments.nextBillingDate) }}</span>
                  <span v-else>{{ formatDate(accountData.accountPayments.nextBillingDate) }}</span>
                </div>
                <div v-if="isSysAdmin" class="flex justify-between items-center">
                  <span class="text-gray-600">Proveedor de pago:</span>
                  <span>{{ accountData.accountPayments.providerName }}</span>
                </div>
                <div v-if="isSysAdmin" class="flex justify-between items-center">
                  <span class="text-gray-600">ID de cliente:</span>
                  <span>{{ accountData.accountPayments.customerId }}</span>
                </div>
                <div v-if="isSysAdmin" class="flex justify-between items-center">
                  <span class="text-gray-600">ID de suscripción:</span>
                  <span>{{ accountData.accountPayments.subscriptionId }}</span>
                </div>
              </div>
            </div>

            <!-- Stripe customer portal -->
            <div class="text-center">
              <button v-if="isStripeProvider" @click="getStripePortalLink"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                {{ $t('accountManagement.manageSubscription') }}
              </button>
            </div>

          </div>

        </div>

      </div>
    </div>

    <!-- Formulario para crear cuenta (solo para SysAdmin) -->
    <div v-if="showAccountForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div class="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
        <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
          <h3 class="text-xl font-bold">Crear nueva cuenta</h3>
          <button @click="closeAccountForm"
            class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
            <XIcon class="h-6 w-6" />
          </button>
        </div>
        <div class="p-6 bg-gray-50">
          <form @submit.prevent="submitNewAccount" class="space-y-4">
            <div class="relative">
              <label for="newAccountName"
                class="absolute left-3 top-2  px-1 bg-white text-xs font-medium text-gray-500">
                Nombre de la nueva cuenta
              </label>
              <input type="text" id="newAccountName" v-model="newAccountForm.name"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Ingrese el nombre de la nueva cuenta" required />
            </div>
            <!-- Añadir aquí los campos adicionales del modelo CreateAccountModel -->
            <div class="relative">
              <label for="newAccountPricingPlan"
                class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Plan de precios
              </label>
              <select id="newAccountPricingPlan" v-model="newAccountForm.pricingPlanId"
                class="mt-1 block w-full px-3 pt-6 pb-2  bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                required>
                <option v-for="plan in pricingPlans" :key="plan.id" :value="plan.id">{{ plan.name }}</option>
              </select>
            </div>
            <div class="flex items-center justify-center pt-4">
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                Crear cuenta
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Popup para crear pago -->
    <div v-if="showPaymentPopup && isSysAdmin"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-4 rounded-md shadow-lg">
        <h3 class="text-lg font-semibold mb-4">Crear nuevo pago</h3>
        <div class="mb-4">
          <label for="paymentDate" class="block text-sm font-medium text-gray-700 mb-1">Fecha de pago</label>
          <input type="date" id="paymentDate" :value="formatDateForInput(paymentDate)"
            @input="e => paymentDate = new Date(e.target.value).toISOString().split('T')[0]"
            class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <div class="flex justify-end space-x-2">
          <button @click="showPaymentPopup = false"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
            Cancelar
          </button>
          <button @click="createPaymentAction" class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
            Crear Pago
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from 'vue';
import { XIcon, Edit2Icon, TrashIcon, DollarSignIcon, BanIcon, Flame, HelpCircleIcon } from 'lucide-vue-next';
import { getAccount, updateAccount, createAccount, getAccountList, deleteAccount as apiDeleteAccount, getPricingPlanList, createPayment, disablePayment, getPaymentStatus, getStripeLink } from '../services/api';
import { useHead } from '@vueuse/head'
import { getCurrentUserRoles } from '../utils/auth';
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const pageTitle = computed(() => t('accountManagement.title'));

const isStripeProvider = computed(() => accountData.value.accountPayments?.providerName === 'Stripe');

useHead({
  title: pageTitle.value,
});

const { showSuccess, showError } = useNotification();

const emit = defineEmits(['close']);

const accountData = ref({
  id: null,
  name: '',
  address: '',
  city: '',
  country: '',
  postalCode: '',
  phoneNumber: '',
  email: '',
  vatNumber: '',
  strictRag: false,
  pricingPlanId: null,
  disabled: false,
  plan: null,
  accountPayments: null
});
const showAccountForm = ref(false);
const newAccountForm = ref({
  name: '',
  address: '',
  city: '',
  country: '',
  postalCode: '',
  phoneNumber: '',
  email: '',
  vatNumber: '',
  strictRag: false,
  pricingPlanId: null
});
const accounts = ref([]);
const pricingPlans = ref([]);
const editingAccount = ref(false);
const paymentStatus = ref('');
const paymentDate = ref(new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]);
const showPaymentPopup = ref(false);
const selectedAccountId = ref(null);
const showTooltip = ref(false);

const isSysAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('SysAdmin');
});

const formatDate = (dateString) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

const submitForm = async () => {
  try {
    const updatedAccountData = {
      ...accountData.value,
      disabled: accountData.value.disabled ? new Date().toISOString() : null
    };
    const response = await updateAccount(accountData.value.id, updatedAccountData);
    if (isSysAdmin.value) {
      await fetchAccounts();
    } else {
      await fetchAccountData();
    }
    showSuccess(response.detail || 'Acción realizada con éxito.');
    editingAccount.value = false;
  } catch (error) {
    showError(error.detail || 'Error al actualizar la cuenta. Por favor, intente de nuevo.');
  }
};

const fetchAccountData = async () => {
  try {
    const account = await getAccount();
    accountData.value = {
      ...account.data,
      disabled: account.data.disabled !== null
    };
    if (!isSysAdmin.value) {
      await fetchPaymentStatus(account.data.id);
    }
  } catch (error) {
    showError(error.detail ||'Error al cargar los datos de la cuenta. Por favor, intente de nuevo.');
  }
};

const fetchAccounts = async () => {
  if (isSysAdmin.value) {
    try {
      const response = await getAccountList();
      accounts.value = response.data;
    } catch (error) {
      showError(error.detail ||'Error al cargar la lista de cuentas. Por favor, intente de nuevo.');
    }
  }
};

const fetchPricingPlans = async () => {
  try {
    const response = await getPricingPlanList();
    pricingPlans.value = response.data;
  } catch (error) {
    showError(error.detail ||'Error al cargar los planes de precios. Por favor, intente de nuevo.');
  }
};

const submitNewAccount = async () => {
  try {
    const response = await createAccount(newAccountForm.value);
    showSuccess(response.detail || 'Acción realizada con éxito.');
    closeAccountForm();
    await fetchAccounts();
  } catch (error) {
    showError(error.detail ||'Error al crear la nueva cuenta. Por favor, intente de nuevo.');
  }
};

const closeAccountForm = () => {
  showAccountForm.value = false;
  newAccountForm.value = { 
    name: '', 
    address: '',
    city: '',
    country: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    vatNumber: '',
    strictRag: false,
    pricingPlanId: null 
  };
};

const editAccount = (account) => {
  accountData.value = { 
    ...account,
    disabled: account.disabled !== null
  };
  editingAccount.value = true;
};

const deleteAccount = async (accountId) => {
  if (confirm('¿Estás seguro de que quieres eliminar esta cuenta?')) {
    try {
      const response = await apiDeleteAccount(accountId);
      showSuccess(response.detail || 'Acción realizada con éxito.');
      await fetchAccounts();
    } catch (error) {
      showError(error.detail ||'Error al eliminar la cuenta. Por favor, intente de nuevo.');
    }
  }
};

const createPaymentAction = async () => {
  try {
    const response = await createPayment(selectedAccountId.value, paymentDate.value);
    showSuccess(response.detail || 'Acción realizada con éxito.');
    showPaymentPopup.value = false;
    await fetchAccounts();
  } catch (error) {
    showError(error.detail || 'Error al crear el pago. Por favor, intente de nuevo.');
  }
};

const disablePaymentAction = async (accountId) => {
  try {
    const response = await disablePayment(accountId);
    showSuccess(response.detail || 'Acción realizada con éxito.');
    await fetchAccounts();
  } catch (error) {
    showError(error.detail || 'Error al deshabilitar el pago. Por favor, intente de nuevo.');
  }
};

const fetchPaymentStatus = async (accountId) => {
  try {
    const response = await getPaymentStatus(accountId);
    paymentStatus.value = response.data;
  } catch (error) {
    showError(error.detail || 'Error al obtener el estado del pago. Por favor, intente de nuevo.');
  }
};

const openPaymentPopup = (accountId) => {
  selectedAccountId.value = accountId;
  showPaymentPopup.value = true;
};

onMounted(async () => {
  if (isSysAdmin.value) {
    await fetchPricingPlans();
    await fetchAccounts();
  } else {
    await fetchAccountData();
  }
});

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getStripePortalLink = async () => {
  try {
    const response = await getStripeLink(`${accountData.value.accountPayments.customerId}`);
    window.open(response.data.url, '_self');
  } catch (error) {
    showError('Error al obtener el enlace del portal de Stripe.');
  }
};
</script>

<style scoped>
.strictRagTooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: normal;
  text-align: center;
  line-height: 1.4;
}

.strictRagTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1f2937 transparent transparent transparent;
}
</style>