export default {
  appName: "Aignis",
  genericError: "An error occurred, please try again later.",
  close: "Close",
  pwa: {
    installTitle: "Install Aignis",
    installDescription: "Install Aignis on your device for quick access",
    install: "Install",
    iosInstructionsTitle: "Install Aignis on iOS",
    iosStep1: "Tap the Share button in your browser.",
    iosStep2: "Scroll down and tap 'Add to Home Screen'.",
    iosStep3: "Tap 'Add' to confirm and install the app.",
    gotIt: "Got it!",
  },
  home: {
    meta: {
      title: "Aignis - Artificial intelligence applied to your knowledge base",
      description:
        "Discover the power of artificial intelligence applied to your knowledge base. Aignis allows you to obtain precise and relevant answers in seconds.",
    },
    menu: {
      about: "Who is Aignis?",
      features: "Features",
      pricing: "Pricing",
      contact: "Contact",
      login: "Log in",
    },
    hero: {
      title: "Empower your knowledge<br>with ",
      subtitle1:
        "Discover the power of artificial intelligence applied to your knowledge base.",
      subtitle2:
        "Aignis allows you to obtain precise and relevant answers in seconds using the information that matters most: yours.",
      cta: "Start now",
      learnMore: "Learn more",
    },
    sections: {
      aboutTitle: "Who is Aignis?",
      aboutContent:
        "Aignis is an artificial intelligence platform that, instead of generating responses based on generic internet information, uses your own data to respond precisely and personally. You can upload your entire knowledge base, from documents to internal files, and Aignis will search for answers exclusively within that information. This ensures that every response is tailored to what you need to know, without having to search through hundreds of pages or databases.",
      featuresTitle: "Features",
      featuresSubtitle: "A better way to manage your knowledge",
      featuresContent:
        "Aignis combines the best of AI with your knowledge base to offer fast and reliable answers based on your information.",
    },
    features: {
      feature1: {
        title: "Personalized knowledge base",
        description:
          "Aignis focuses on using only your data, gathering information from your documents and connections to your databases to answer your questions. This ensures that every interaction is fully aligned with your content and information. You no longer need to worry about receiving irrelevant or generic answers; Aignis delivers accuracy by drawing directly from your knowledge.",
      },
      feature2: {
        title: "Full control",
        description:
          "You decide what information the AI loads, always maintaining control over your data. The advanced plan offers the option of a completely local installation, where both the AI model and your knowledge base reside on your own servers. This way, the data never leaves your organization, ensuring the highest level of privacy.",
      },
      feature3: {
        title: "Easy integration",
        description:
          "Thanks to our <strong>simple but powerful API</strong>, you can quickly integrate Aignis with your existing systems. This means you can start leveraging the full potential of artificial intelligence without the need for complex technical implementations. Aignis will be ready to process and provide answers within your current workflows.",
      },
      feature4: {
        title: "Scalable capacity",
        description:
          "Our infrastructure is designed to handle large volumes of data and queries, ensuring fast responses regardless of your organization’s size. Additionally, our system allows you to add more capacity when needed, ensuring that your knowledge base is always available and up to date.",
      },
    },
    pricing: {
      title: "Pricing plans",
      subtitle: "Choose the plan that best suits your needs",
      plans: {
        whatIncludes: "What does it include?",
        from: "From ",
        perMonth: " /month",
        knowledgeBase: "Knowledge base capacity ",
        conversationTokens: " conversation tokens per month",
        knowledgeTokens: " knowledge tokens per month",
        users: " users",
        tier0: {
          name: "Spark",
          description: "For students and individuals",
          price: "Free",
          cta: "Register for free",
          includes: {
            knowledgeBase: "50 MB knowledge base",
            conversationTokens: "500,000 tokens per month *",
            languageModel: "Basic language model",
            users: "1 user",
          },
        },
        tier1: {
          name: "Flame",
          description: "For startups and professionals",
          price: "29€",
          cta: "Subscribe",
          includes: {
            knowledgeBase: "10 GB knowledge base",
            conversationTokens: "6,000,000 tokens per month *",
            languageModel: "Extended language model",
            users: "3 users",
            support: "Email support",
          },
        },
        tier2: {
          name: "Glow",
          description: "For growing companies",
          price: "149€",
          cta: "Subscribe",
          includes: {
            knowledgeBase: "50 GB knowledge base",
            conversationTokens: "30,000,000 tokens per month *",
            languageModel: "Advanced language model",
            users: "20 users",
            support: "Priority email support",
            integration: "Integration API",
            databases: "1 database connection",
          },
        },
        tier3: {
          name: "Radiance",
          description: "For large organizations",
          price: "749€",
          cta: "Contact us",
          includes: {
            knowledgeBase: "250 GB knowledge base",
            conversationTokens: "150,000,000 tokens per month *",
            languageModel: "Custom language model",
            users: "Unlimited users",
            support: "Dedicated agent support",
            integration: "Integration API",
            databases: "5 databases connections",
            localSetup: "Local installation without external connection ***",
          },
        },
      },
      notes:
        "<em>* 500,000 tokens are approximately equivalent to 375,000 words or 850 pages. For example, the book 'Don Quixote'.</em><br><em>Tokens are counted for all your users, both for questions and for the context of your knowledge base and the AI’s responses.</em> <br><br> <em>** Connects to your database to obtain information (SQL Server, MySql, PostgreSql, SQLite).</em>  <br><br> <em>*** Requires additional configuration and may have extra costs.</em>",
    },
    contact: {
      title: "Contact",
      subtitle: "Do you have any questions? We are here to help you.",
      emailPrompt: "Send us an email:",
      responseTime: "We will respond as soon as possible.",
    },
    footer: {
      terms: "Terms of use",
      legalNotice: "Legal notice",
      privacyPolicy: "Privacy policy",
      copyright: "All rights reserved.",
    },
  },
  signup: {
    meta: {
      title: "Sign up for Aignis",
      description:
        "Sign up for Aignis and start enjoying all the benefits of artificial intelligence applied to your knowledge base.",
    },
    title: "Sign up for Aignis",
    name: "Full name",
    namePlaceholder: "Your name or your organization",
    email: "Email address",
    emailPlaceholder: "Your email",
    address: "Address",
    addressPlaceholder: "Example Street 42, 1st floor",
    postalCode: "Postal Code",
    postalCodePlaceholder: "Enter your postal code",
    city: "City",
    cityPlaceholder: "Enter your city",
    country: "Country",
    countryPlaceholder: "Enter your country",
    phone: "Phone",
    phonePlaceholder: "Enter your phone number",
    vatNumber: "VAT number",
    vatNumberPlaceholder: "Only if you need an invoice",
    readConditions: "I have read and accept the ",
    linkConditions: "terms of use",
    readPrivacy: "I have read and accept the ",
    linkPrivacy: "privacy policy",
    cta: "Register",
    ctaing: "Registering...",
    emailRegistered: "The email is already registered",
    changeOrCancelAtAnyTime:
      "By signing up, you will create a subscription to this plan. You can change or cancel your subscription at any time.",
    selectOtherPlan: "You can also choose another plan:",
    countryNotValid: "Select a valid country from the list",
    acceptConditions: "You must accept the terms of use and privacy policy to continue",
  },
  successPage: {
    title: "Registration completed!",
    text: "Thank you for registering, your account is being created. In a few seconds, <strong>you will receive an email</strong> with your access credentials.",
    credentialsReceived: "Have you already received your credentials?",
    cta: "Log in",
  },
  cancelPage: {
    title: "Registration cancelled",
    text: "Your registration has been cancelled. If you have any questions about the service or any issues with your registration, please contact us.",
    tryAgain: "Do you want to try again?",
    cta: "Register a new account",
  },
  login: {
    meta: {
      title: "Log in to Aignis",
      description:
        "Access your Aignis account and start enjoying all the benefits of artificial intelligence applied to your knowledge base.",
    },
    userName: "Email",
    userNamePlaceholder: "Your email",
    password: "Password",
    passwordPlaceholder: "Your password",
    remember: "Remember session",
    cta: "Log in",
    ctaing: "Logging in...",
    forgotPassword: "Forgot your password?",
    noAccount: "Don't have an account?",
    signup: "Sign up here",
    error: "Error",
    success: "Success",
    email: "Email",
    emailPlaceholder: "Your email",
    resetPasswording: "Resetting password...",
    resetPassword: "Reset password",
    newPassword: "New password",
    newPasswordPlaceholder:
      "Min. 8 characters, 1 lowercase, 1 uppercase, 1 number",
    notForgetPassword: "Remember your password now?",
    login: "Back to login",
    passwordResetSent:
      "The link to reset your password has been sent to your email.",
    passwordChanged:
      "Your password has been successfully reset. You will be redirected to the login page.",
  },
  chat: {
    meta: {
      title: "Chat with Aignis",
      description:
        "Talk to Aignis, your virtual assistant, and get precise and relevant answers in seconds.",
    },
    conversations: "Conversations",
    newConversation: "Start new conversation",
    confirmDeleteConversation:
      "Are you sure you want to delete this conversation?",
    cancel: "Cancel",
    delete: "Delete",
    title: "Chat with Aignis",
    menuTitle: "Menu",
    menu: {
      knowledge: "Knowledge base",
      settings: "Settings",
      yourUser: "Your user",
      userManagement: "User management",
      accountManagement: "Account management",
      accountUsage: "Account usage",
      pricingPlans: "Pricing plans",
      AIproviders: "AI providers",
      logout: "Log out",
      language: "Language",
    },
    copyToClipboard: "Copy to clipboard",
    copyCode: "copy code",
    writeYourMessage: "Write your message...",
    disclaimer:
      "Aignis may make mistakes, please verify important information.",
    terms: "Terms of Use",
    thinking: "Aignis is thinking...",
    writing: "Aignis is writing...",
    welcome: "Hello, I am **Aignis**, how can I help you today?",
    welcomeAgain: "Welcome back! What should we do now?",
    requiresDatabaseQuery: "This question requires a database query",
    selectDatabase: "Select a database connection",
  },
  accountManagement: {
    title: "Account Management",
    accountData: "Your Account Data",
    name: "Account Name",
    namePlaceholder: "Your name or your organization's name",
    address: "Address",
    addressPlaceholder: "Example Street 42, floor 1",
    postalCode: "Postal Code",
    postalCodePlaceholder: "Enter your postal code",
    city: "City",
    cityPlaceholder: "Enter your city",
    country: "Country",
    countryPlaceholder: "Enter your country",
    phone: "Phone",
    phonePlaceholder: "Enter your phone",
    email: "Email",
    emailPlaceholder: "Your email",
    vatNumber: "VAT Number",
    vatNumberPlaceholder: "Only if you need an invoice",
    save: "Save Changes",
    planData: "Your Plan Data",
    users: "Users: ",
    knowledgeBase: "Knowledge Base: ",
    knowledgeTokens: "Knowledge Tokens: ",
    perMonth: " per month",
    conversationTokens: "Conversation Tokens: ",
    subscriptionData: "Your Subscription Data",
    subscriptionStatus: "Subscription Status: ",
    active: "Active",
    inactive: "Inactive",
    amount: "Amount: ",
    lastPayment: "Last Payment: ",
    nextPayment: "Next Payment: ",
    willCancel: "Will be canceled on ",
    manageSubscription: "Manage Your Subscription",
    strictRag: "Enable strict mode",
    strictRagExplanation:
      "When enabled, Aignis will only provide answers based on the information in your knowledge base, without using external sources. This ensures that the responses are always based on your data.",
  },
  accountUsage: {
    title: "Account Usage",
    subTitle: "Usage Data per Month",
    year: "Year: ",
    loadingData: "Loading data...",
    users: "Users",
    knowledgeBase: "Knowledge Base",
    knowledgeTokens: "Knowledge Tokens",
    conversationTokens: "Conversation Tokens",
    noDataForPeriod: "No data available for the selected period.",
    noPermission: "You do not have permission to view this information.",
    noLimit: "No limit",
  },
  usersManagement: {
    title: "User Management",
    explanation:
      "You can add users with the ADMIN or USER role. The Admin can manage files, account data, and create or delete users. The User can interact with the AI that will have knowledge of your documents but cannot manage files or other users.",
    add: "Add ",
    edit: "Edit ",
    user: "User",
    admin: "Admin",
    addUser: "Add user",
    userName: "Name",
    userNamePlaceholder: "User name",
    email: "Email",
    emailPlaceholder: "User email",
    roles: "Role",
    access: "Access",
    active: "Active",
    inactive: "Inactive",
    lastAccess: "Last access",
    noUsers: "There are no other users in the account.",
    userNamePlaceholder: "User's name",
    password: "Password",
    passwordPlaceholderLeaveBlank: "Leave blank to keep the current one",
    passwordPlaceholderRequirements:
      "Min. 8 characters, 1 lowercase, 1 uppercase, 1 number",
    noAllowAccess: "Do not allow access to this user",
    deleteConfirmation: "Are you sure you want to delete this user?",
    cancel: "Cancel",
    delete: "Delete",
  },
  knowledgeBase: {
    title: "Knowledge Base",
    uploading: "Adding files to the AI knowledge base",
    explanation:
      "Upload relevant files and connect your databases to Aignis's knowledge base so the AI can provide more accurate and useful answers tailored to your organization’s specific information. The more resources you provide, whether files or databases, the better Aignis can understand and process your data, delivering comprehensive and customized responses to meet your needs.",
    uploadExplanation:
      "The files you upload will be stored encrypted and cannot be downloaded. Only relevant snippets of the documents will be sent to the natural language model to answer user questions, but these snippets will not be stored or used to train the model.",
    databaseExplanation:
      "Database connections you add will be encrypted and kept secure, and the data queried will not be stored locally. Only the relevant fragments of the necessary information will be sent to the language model to answer user questions, but these fragments will not be stored or used to train the model.",
    close: "Close",
    addDocuments: "Add documents",
    dragFilesOrClick: "Drag files here or click to select them",
    upload: "Upload files",
    uploading: "Uploading files...",
    existingFiles: "Files",
    name: "Name",
    size: "Size",
    creationDate: "Creation date",
    noFiles:
      "There are no files in the knowledge base, click the button to add your files.",
    file: "File ",
    uploadedOk: " uploaded successfully",
    uploadedError: "Error uploading the file ",
    allOk: "All files uploaded successfully",
    someKo: "Some files could not be uploaded",
    deletedOk: "File deleted successfully",
    deletedError: "Error deleting the file",
    fetchFilesError: "Error fetching files from the knowledge base",
    dbConnections: "Database connections",
    addDbConnection: "Add database connection",
    editDbConnection: "Edit database connection",
    provider: "Database provider",
    databaseProviders: {
      SqlServer: "Microsoft SQL Server",
      MySql: "MySQL / MariaDB",
      PostgreSql: "PostgreSQL",
      SQLite: "SQLite",
    },
    connectionName: "Connection name",
    connectionNamePlaceholder: "Name for this connection",
    connectionString: "Connection string",
    connectionStringPlaceholder:
      "Server=myServerAddress;Database=myDataBase;User Id=myUsername;Password=myPassword;",
  },
  userProfile: {
    title: "Your User",
    name: "Username",
    namePlaceholder: "Your name",
    email: "Email",
    emailPlaceholder: "Your email",
    password: "Password",
    passwordPlaceholder: "Leave blank to keep the current one",
    save: "Save changes",
    passwordChanged: "Your password has been changed successfully.",
    lastFailedLogin: "Last failed login attempt: ",
    getDataError: "Error retrieving user data",
  },
};
